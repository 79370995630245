import React from 'react';
// import LoadingImg from '../../assets/images/loading.svg';
import LoadingImg from '../../assets/images/loading_icon.jpg';
import './Loading.scss';

const Loading = (props) => {
    return (
        <div className="loading-container ">
           <div className="loading d-flex justify-content-center align-self-center" >
                <img alt="" className=" animate__animated animate__rotateIn" src={LoadingImg}/>
            </div>
        </div>
    );
}

export default Loading;
