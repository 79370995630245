
import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Loading from './views/pages/Loading';

const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout/DefaultLayout'));

class App extends Component {
  
  loading = () => <div className="animate__animated animate__fadeIn"><Loading /></div>
  
  async componentDidMount() {
  }

  render() {
    const routes = (
      <Switch>
        <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
      </Switch>
    )

    return (
      <React.Suspense fallback={this.loading()}>
        <BrowserRouter>
          {routes}
        </BrowserRouter>
      </React.Suspense>
    )
  }
}

export default App;
